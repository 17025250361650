.modalOverlay {
  position: absolute;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;

  .modal {
    height: fit-content;
    width: 69rem;
    background-color: white;
    border-radius: 2rem;
    padding: 4rem;
    position: absolute;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 2px 4px 0px, rgba(14, 30, 37, 0.12) 0px 2px 16px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;




    .imgSlider {
      display: flex;
      flex-direction: row;
      overflow: scroll;
      //align-items: center;
      //justify-content: center;
      width: 100%;

    }
    .imgSlider::-webkit-scrollbar {
      display: none;
    }
    .imgSlider {
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
    }
    img {
      height: 65rem;
      width: auto;
      margin: 0 auto;
    }

    .bottom {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      justify-content: space-between;
      .text {
        width: 50%;
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        gap: 1rem;
        justify-content: space-between;

        .text-right, .text-left {
          display: flex;
          flex-direction:column;
          gap: 1rem;
          align-items: flex-start;
          justify-content: flex-start;
        }
      }
      a {
        margin: 0;
      }
    }


    a {
      font-size: 5rem;
      color: deeppink;
    }
  }
}

h1 {
  margin-top: 20px;
  font-weight: 800;
  text-align: center;
}
h3 {
  font-weight: 700;
  font-size: 2.4rem;
  text-align: center;
}




.btn {
  position: absolute;
  top: 2rem;
  right: 2rem;
  font-size: 2.4rem;
  padding: 0;
  margin: 0;
  align-items: center;
  display: flex;
  background-color: transparent;
  border: none;
  cursor: pointer;
}


// Phone
@media only screen and (max-width: 768px) {
  .modalOverlay {
    .modal {
      width: 90vw;
      img {
        width: 27rem;
        margin: 0 auto;
      }

      .imgSlider {
        overflow: scroll;
        gap: 1rem;
        justify-content: flex-start;
      }

      a {
        margin-top: 4rem;
      }
    }
  }
}

@media only screen and (max-width: 430px) {
  .modalOverlay {
    .modal {
      width: 90vw;

      h1 {
        font-size: 2.2rem;
      }
      h3 {
        font-size: 1.8rem;
      }

      img {
        width: 69vw;
        height: auto;
        margin: 0 auto;
      }

      .imgSlider {
        overflow: scroll;
        gap: 1rem;
        justify-content: flex-start;
      }

      a {
        margin-top: 4rem;
      }
    }
  }
}

