.UploadPage {
  height: 100%;
  width: 100%;
  font-size: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;

  form {
    width: 100%;
    max-width: 60rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    margin: 0 auto;
    background-color: rgba(250, 250, 250, 1);
    padding: 3rem;

    h1 {
      margin: 0;
    }

    button {
      font-size: 2rem;
      width: fit-content;
      margin: 0 auto;
      padding: 1rem 2rem;
      cursor: pointer;
    }
  }
  input {
    height: 4rem;
    font-size: 2rem;
    padding: 1rem;
  }
}
