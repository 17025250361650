.footer {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 8rem;
  align-items: center;
  justify-content: center;
  //padding: 20px;
  background: white;
  gap: 1rem;

  a {
    margin: 0 auto;
    padding: 0;
    color: blue;
    text-decoration: none;
    display: flex;
    align-items: center;
  }

  img {
    width: 14rem;
    height: auto;
  }
}

@media only screen and (max-width: 430px) {
  .footer {
    font-size: 1.4rem;
    gap: .5rem;
    min-height: 7rem;

    img {
      width: 10rem;
    }
  }
}
