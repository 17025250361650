.home {
  //background-image: url("${BackgroundImg}");
  background-position: center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  flex: 1;


  .under-construction {
    background-color: white;
    display: flex;
    flex-direction: column;
    width: fit-content;
    margin: 50px auto;
    padding: 40px;
    border-radius: 10px;
    align-items: center;
    justify-content: center;
    box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
  }

  Footer {
    bottom: 0;
    position: absolute;
  }
}
