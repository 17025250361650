.HomePage {
  height: calc(100% - 8rem - 15rem);
  width: 100vw;
  //background-color: blue;

  .container {
    //background-color: red;
    max-width: 120rem;
    height: 100%;
    margin: 0 auto;
    padding: 10rem 0 0;
    overflow: scroll;
    position: relative;




    $finalHeight: 450px;
    $finalWidth: 3 * $finalHeight;
    $scrollBarHeight: 1px;

    ::-webkit-scrollbar {
      width: $scrollBarHeight;
      height: $scrollBarHeight;
    }

    ::-webkit-scrollbar-button {
      width: $scrollBarHeight;
      height: $scrollBarHeight;
    }

    .horizontal-scroll-wrapper {
      //position: absolute;
      display: block;
      //top: 0;
      //left: 0;
      width: calc(#{$finalHeight} + #{$scrollBarHeight});
      max-height: $finalWidth;
      margin: 0;
      //padding-top: $scrollBarHeight;
      //background: #abc;
      //overflow-y: auto;
      overflow-x: hidden;
      transform: rotate(-90deg) translateY(-$finalHeight);
      transform-origin: right top;
      & > div {
        //display: block;
        //padding: 5px;
        //background: #cab;
        transform: rotate(90deg);
        transform-origin: right top;
        padding: 1rem;
      }
      .card {
        height: fit-content;
        img {
          height: 40rem;
          border-radius: 2rem;
          //box-shadow: rgba(0, 0, 0, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
          cursor: pointer;
          //box-shadow: rgba(122, 25, 25, 0.4) 5px 5px, rgba(123, 20, 20, 0.3) 10px 10px, rgba(150, 0, 25, 0.2) 15px 15px, rgba(240, 46, 170, 0.1) 20px 20px, rgba(240, 46, 170, 0.05) 25px 25px;
        }
        .modalBtn {
          background-color: transparent;
          padding: 0;
          border: 0;
        }
      }
    }

    .squares {
      padding: $finalHeight 0 0 0;
      & > div {
        width: $finalHeight;
        height: $finalHeight;
        //margin: 10px 0;
      }
    }

    .Marquee {
      //background: -webkit-linear-gradient(225deg, #008ed9, #8b00db);
      //background: -moz-linear-gradient(225deg, #008ed9, #8b00db);
      //background: -o-linear-gradient(225deg, #008ed9, #8b00db);
      //background: -ms-linear-gradient(225deg, #008ed9, #8b00db);
      //background: linear-gradient(-135deg, #008ed9, #8b00db);
      width: 100%;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      //padding: 1em;
      color: #fff;
      font-weight: 200;
      display: -webkit-box;
      display: -moz-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      //display: box;
      display: flex;
      -webkit-box-align: center;
      -moz-box-align: center;
      -o-box-align: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      align-items: center;
      overflow: hidden;
    }
    .Marquee-content {
      width: fit-content;
      display: -webkit-box;
      display: -moz-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      //display: box;
      display: flex;
      -webkit-animation: marquee 80s linear infinite running;
      -moz-animation: marquee 80s linear infinite running;
      -o-animation: marquee 80s linear infinite running;
      -ms-animation: marquee 80s linear infinite running;
      animation: marquee 80s linear infinite running;
      //animation-delay: 4s;
      //overflow: scroll;
    }
    .Marquee-content:hover {
      -webkit-animation-play-state: paused;
      -moz-animation-play-state: paused;
      -o-animation-play-state: paused;
      -ms-animation-play-state: paused;
      animation-play-state: paused;
    }
    .Marquee-tag {
      width: fit-content;
      margin: 0 2em;
      padding: 0.5em;
      background: rgba(255,255,255,0.1);
      //display: -webkit-inline-box;
      display: -moz-inline-box;
      display: -webkit-inline-flex;
      display: -ms-inline-flexbox;
      //display: inline-box;
      display: inline-flex;
      -webkit-box-align: center;
      -moz-box-align: center;
      -o-box-align: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      align-items: center;
      -webkit-box-pack: center;
      -moz-box-pack: center;
      -o-box-pack: center;
      -ms-flex-pack: center;
      -webkit-justify-content: center;
      justify-content: center;
      -webkit-transition: all 0.2s ease;
      -moz-transition: all 0.2s ease;
      -o-transition: all 0.2s ease;
      -ms-transition: all 0.2s ease;
      transition: all 0.2s ease;

      .card {
        height: fit-content;
        img {
          height: 45rem;
          border-radius: 2rem;
          //box-shadow: rgba(0, 0, 0, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
          cursor: pointer;
          //box-shadow: rgba(122, 25, 25, 0.4) 5px 5px, rgba(123, 20, 20, 0.3) 10px 10px, rgba(150, 0, 25, 0.2) 15px 15px, rgba(240, 46, 170, 0.1) 20px 20px, rgba(240, 46, 170, 0.05) 25px 25px;
        }
        img:hover {
          filter: drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.2));
          //box-shadow: rgba(240, 46, 170, 0.4) 5px 5px, rgba(240, 46, 170, 0.3) 10px 10px, rgba(240, 46, 170, 0.2) 15px 15px, rgba(240, 46, 170, 0.1) 20px 20px, rgba(240, 46, 170, 0.05) 25px 25px;
        }
        .modalBtn {
          background-color: transparent;
          padding: 0;
          border: 0;
        }
      }
    }
    .Marquee-tag:hover {
      background: rgba(255,255,255,0.5);
      -webkit-transform: scale(1.1);
      -moz-transform: scale(1.1);
      -o-transform: scale(1.1);
      -ms-transform: scale(1.1);
      transform: scale(1.1);
      cursor: pointer;
    }
    @-moz-keyframes marquee {
      0% {
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -o-transform: translateX(0);
        -ms-transform: translateX(0);
        transform: translateX(0);
      }
      100% {
        -webkit-transform: translate(-100%);
        -moz-transform: translate(-100%);
        -o-transform: translate(-100%);
        -ms-transform: translate(-100%);
        transform: translate(-100%);
      }
    }
    @-webkit-keyframes marquee {
      0% {
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -o-transform: translateX(0);
        -ms-transform: translateX(0);
        transform: translateX(0);
      }
      100% {
        -webkit-transform: translate(-100%);
        -moz-transform: translate(-100%);
        -o-transform: translate(-100%);
        -ms-transform: translate(-100%);
        transform: translate(-100%);
      }
    }
    @-o-keyframes marquee {
      0% {
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -o-transform: translateX(0);
        -ms-transform: translateX(0);
        transform: translateX(0);
      }
      100% {
        -webkit-transform: translate(-100%);
        -moz-transform: translate(-100%);
        -o-transform: translate(-100%);
        -ms-transform: translate(-100%);
        transform: translate(-100%);
      }
    }
    @keyframes marquee {
      0% {
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -o-transform: translateX(0);
        -ms-transform: translateX(0);
        transform: translateX(0);
      }
      100% {
        -webkit-transform: translate(-100%);
        -moz-transform: translate(-100%);
        -o-transform: translate(-100%);
        -ms-transform: translate(-100%);
        transform: translate(-100%);
      }
    }

    .button-container {
      width: 100%;
      padding: 2rem 0;
      display: flex;
      justify-content: center;
      align-items: center;

      .btn-more {
        font-size: 2.4rem;
        background: none;
        border: 1px solid black;
        padding: 1rem 4.5rem;
        cursor: pointer;
        transition-timing-function: ease-in-out;
      }
      .btn-more:hover{
        transform: scale(1.1);
        transition-duration: 500ms;
        transition-timing-function: ease-in-out;
      }
    }


    .allItems {
      width: 100%;
      display: none;
      flex-wrap: wrap;
      justify-content: space-between;
      gap: 3rem;

      .allItems-card {
        height: fit-content;
        width: fit-content;
        img {
          cursor: pointer;
        }
        img:hover {
          filter: drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.2));
        }
        .modalBtn {
          background-color: transparent;
          padding: 0;
          border: 0;
        }
      }

      img {
        width: 25rem;
      }
    }
    .active {
      display: flex;
    }
  }
  .container::-webkit-scrollbar {
    display: none;
  }
  .container {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }

  .carousel {
    .container {
      position: relative;
      width: 320px;
      margin: 100px auto 0 auto;
      perspective: 1000px;
    }

    .carousel {
      position: absolute;
      width: 100%;
      height: 100%;
      transform-style: preserve-3d;
      animation: rotate360 60s infinite forwards linear;
    }
    .carousel__face {
      position: absolute;
      width: 300px;
      height: 187px;
      top: 20px;
      left: 10px;
      right: 10px;
      background-size: cover;
      box-shadow:inset 0 0 0 2000px rgba(0,0,0,0.5);
      display: flex;
    }

    span {
      margin: auto;
      font-size: 2rem;
    }


    .carousel__face:nth-child(1) {
      background-image: url("https://images.pexels.com/photos/1141853/pexels-photo-1141853.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260");
      transform: rotateY(  0deg) translateZ(430px); }
    .carousel__face:nth-child(2) {
      background-image: url("https://images.pexels.com/photos/1258865/pexels-photo-1258865.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260");
      transform: rotateY( 40deg) translateZ(430px); }
    .carousel__face:nth-child(3) {
      background-image: url("https://images.pexels.com/photos/808466/pexels-photo-808466.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260");
      transform: rotateY( 80deg) translateZ(430px); }
    .carousel__face:nth-child(4) {
      background-image: url("https://images.pexels.com/photos/1394841/pexels-photo-1394841.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260");
      transform: rotateY(120deg) translateZ(430px); }
    .carousel__face:nth-child(5) {
      background-image: url("https://images.pexels.com/photos/969679/pexels-photo-969679.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260");
      transform: rotateY(160deg) translateZ(430px); }
    .carousel__face:nth-child(6) {
      background-image: url("https://images.pexels.com/photos/1834400/pexels-photo-1834400.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260");
      transform: rotateY(200deg) translateZ(430px); }
    .carousel__face:nth-child(7) {
      background-image: url("https://images.pexels.com/photos/1415268/pexels-photo-1415268.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260");
      transform: rotateY(240deg) translateZ(430px); }
    .carousel__face:nth-child(8) {
      background-image: url("https://images.pexels.com/photos/135018/pexels-photo-135018.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260");
      transform: rotateY(280deg) translateZ(430px); }
    .carousel__face:nth-child(9) {
      background-image: url("https://images.pexels.com/photos/1175135/pexels-photo-1175135.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260");
      transform: rotateY(320deg) translateZ(430px); }



    @keyframes rotate360 {
      from {
        transform: rotateY(0deg);
      }
      to {
        transform: rotateY(-360deg);
      }
    }
  }

  .clothes-section {
    width: 100%;
    background-color: purple;
    padding: 2rem;
    display: flex;
    flex-direction: row;
    gap: 2rem;
    overflow: scroll;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */

    .card {

      img {
        height: 40rem;
        border-radius: 2rem;

      }
    }
  }
  .clothes-section::-webkit-scrollbar {
    display: none;
  }

}

@media only screen and (max-width: 768px) {
  .HomePage {
    .container {
      padding: 0 0 0;

      .Marquee {
        .Marquee-content {
          -webkit-animation: marquee 100s linear infinite running;
          -moz-animation: marquee 100s linear infinite running;
          -o-animation: marquee 100s linear infinite running;
          -ms-animation: marquee 100s linear infinite running;
          animation: marquee 100s linear infinite running;
          //overflow: scroll;
        }
      }
    }
  }

}

@media only screen and (max-width: 430px) {
  .HomePage {
      height: calc(100% - 7rem - 10rem);
    .container {
      padding: 0 0 0;

      .Marquee {
        .Marquee-content {
          .Marquee-tag {
            .card {
              height: fit-content;
              img {
                height: 35rem;
                border-radius: 2rem;
                //box-shadow: rgba(0, 0, 0, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
                cursor: pointer;
                //box-shadow: rgba(122, 25, 25, 0.4) 5px 5px, rgba(123, 20, 20, 0.3) 10px 10px, rgba(150, 0, 25, 0.2) 15px 15px, rgba(240, 46, 170, 0.1) 20px 20px, rgba(240, 46, 170, 0.05) 25px 25px;
              }
              .modalBtn {
                background-color: transparent;
                padding: 0;
                border: 0;
              }
            }
          }
        }
      }
      .button-container {
        width: 100vw;

        .btn-more {
          font-size: 1.6rem;
          padding: 1rem 3rem;
        }
      }

      .allItems {
        width: 100vw;
        flex-wrap: wrap;
        justify-content: space-between;
        gap: 1rem;
        padding: 0 10px;

        .allItems-card {
          height: fit-content;
          width: 45%;
          img {
            cursor: pointer;
          }
          img:hover {
            filter: drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.25));
          }
          .modalBtn {
            background-color: transparent;
            padding: 0;
            border: 0;
          }
        }

        img {
          width: 100%;
        }
      }
    }
  }

}

@media only screen and (max-width: 380px) {
  .HomePage {
    .container {
      padding: 0 0 0;

      .Marquee {
        .Marquee-content {
          .Marquee-tag {
            .card {
              height: fit-content;
              img {
                height: 35rem;
                border-radius: 2rem;
                //box-shadow: rgba(0, 0, 0, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
                cursor: pointer;
                //box-shadow: rgba(122, 25, 25, 0.4) 5px 5px, rgba(123, 20, 20, 0.3) 10px 10px, rgba(150, 0, 25, 0.2) 15px 15px, rgba(240, 46, 170, 0.1) 20px 20px, rgba(240, 46, 170, 0.05) 25px 25px;
              }
              .modalBtn {
                background-color: transparent;
                padding: 0;
                border: 0;
              }
            }
          }
        }
      }
    }
  }

}
