.Header {
  height: 15rem;
  //background-color: orange;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    height: 16rem;
  }
}

@media only screen and (max-width: 430px) {
  .Header {
    height: 10rem;
    img {
      height: 14rem;
    }
  }
}
