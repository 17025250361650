:root {
  --background: white;
  --card-background: #f6f7fb;
  --text-primary: #2d2f4b;
  --text-secondary: #800000;
  --accent: purple;
  --tan: #FAEBD8;
  --red: #800000;
}
[data-theme='"dark"'] {
  --background: #393f64Z;
  --card-background: #a3aac0;
  --text-primary: #f6f7fb;
  --text-secondary: #FAEBD8;
  --accent: darkred;
}

html {
  font-size: 10px;
}
html,
body {
  margin: 0;
  padding: 0;
}

html,
body,
#root,
.App {
  height: 100%;
  background-color: var(--background);
  color: var(--text-primary);
  overflow: hidden;
}
*,
::before,
::after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  font-family: "Open Sans", sans-serif;
  font-size: 1.8rem;
  line-height: 1;
  color: var(--text-primary);
}

a,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  display: block;
  padding: 0;
  margin: 0;
  width: fit-content;
}

a {
  text-decoration: none;
  cursor: pointer;
}

a,
a:hover {
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

// Mobile
@media only screen and (max-width: 980px) {
  //html {
  //  font-size: 9px;
  //}
}
